import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { AuthComponents, State } from '@/store/models';
import Loader from '@/components/common/loader/Loader.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import Footer from '@/components/common/footer/Footer.vue';
import Login from '@/components/common/auth/login/Login.vue';
import AuthVerification from '@/components/common/auth/verification/AuthVerification.vue';
import Reset from '@/components/common/auth/reset/Reset.vue';
import Register from '@/components/common/auth/register/Register.vue';
import { capitalize } from '@/filters/string';

@Component({
  components: {
    Loader,
    Navbar,
    Footer,
  },
})

export default class AuthPage extends Vue {
  @StateClass auth!: State['auth'];
  @StateClass authModal!: State['authModal'];
  @Action openModal!: Function;

  @Prop({ default: '/landing' })
  logoLink!: string;

  @Prop({ default: 'snake-case-id' })
  identifier!: string;

  @Prop({ default: null })
  initWithType!: AuthComponents;

  get loading(): boolean {
    return !!this.auth && this.auth.status === 'processing';
  }

  get title(): string {
    switch (this.initWithType) {
      case AuthComponents.Login:
        return capitalize(this.$t('common.login') as string);
      case AuthComponents.Reset:
        return capitalize(this.$t('common.reset') as string);
      case AuthComponents.AuthVerification:
        return capitalize(this.$t('meta.verification.title') as string);
      case AuthComponents.Register:
        return capitalize(this.$t('meta.register.title') as string);
      default:
        return '';
    }
  }

  get currentComponentProps(): any {
    return { isAuthPage: true };
  }

  get currentComponent(): any {
    switch (this.initWithType) {
      case AuthComponents.Login:
        return Login;
      case AuthComponents.AuthVerification:
        return AuthVerification;
      case AuthComponents.Reset:
        return Reset;
      case AuthComponents.Register:
        return Register;
      default:
        return null;
    }
  }
}
